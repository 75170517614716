// Base game class
import ParticleBlast from "./ParticleBlast";
import MotionHandler from "./MotionHandler";

export default class Pong {
  constructor(canvas) {
    // Math values
    this.width = canvas.getBoundingClientRect().width;
    this.height = canvas.getBoundingClientRect().height;
    // Dom elements
    this.canvas = canvas;
    this.ctx = null;
    this.keystate = null;
    // Player paddle
    this.player = {
      x: null,
      y: null,
      width: this.width * 0.1,
      height: 20,
    };
    // AI paddle
    this.ai = {
      x: null,
      y: null,
      width: this.width * 0.1,
      height: 20,
    };
    // The ball object
    this.ball = {
      x: null,
      y: null,
      vel: null,
      side: 20,
      speed: 4,
    };
    this.playerScore = 0;
    this.aiScore = 0;
    this.explosions = [];
    // Interaction helper
    this.interacted = false;
  }
  // Update paddle position
  updatePlayer() {
    // keep the paddle inside of the canvas
    this.player.x = Math.max(
      Math.min(this.player.x, this.width - this.player.width),
      0
    );
  }
  // Draw player paddle to canvas
  drawPlayer() {
    this.ctx.fillRect(
      this.player.x,
      this.player.y,
      this.player.width,
      this.player.height
    );
  }
  // Update AI position
  updateAi() {
    // calculate ideal position
    let destination = this.ball.x - (this.ai.width - this.ball.side) * 0.5;

    // ease the movement towards the position
    this.ai.x += (destination - this.ai.x) * 0.035;
    // keep the paddle inside of the canvas
    this.ai.x = Math.max(Math.min(this.ai.x, this.width - this.ai.width), 0);
  }
  // Draw AI paddle to canvas
  drawAi() {
    this.ctx.fillRect(this.ai.x, this.ai.y, this.ai.width, this.ai.height);
  }
  serveBall(side) {
    let offset = Math.random();
    // Direct ball position
    this.ball.y = this.height / 2;
    this.ball.x = (this.width - this.ball.side) * offset;
    // Calculate angle
    let angle = 0.1 * Math.PI * (1 - 2 * offset);
    // Set velocity
    this.ball.vel = {
      y: side * this.ball.speed * Math.cos(angle),
      x: this.ball.speed * Math.sin(angle),
    };
  }
  updateBall() {
    if (this.playing) {
      this.ball.x += this.ball.vel.x;
      this.ball.y += this.ball.vel.y;
      // Keep in bounds
      if (this.ball.x < 0 || this.ball.x + this.ball.side > this.width) {
        let offset =
          this.ball.vel.x < 0
            ? 0 - this.ball.x
            : this.width - (this.ball.x + this.ball.side);
        this.ball.x += 2 * offset;
        // Change direction
        this.ball.vel.x *= -1;
      }
      // Change paddle based on ball direction
      let paddle = this.ball.vel.y < 0 ? this.ai : this.player;
      if (
        this.intersectHelper(
          paddle.x,
          paddle.y,
          paddle.width,
          paddle.height,
          this.ball.x,
          this.ball.y,
          this.ball.side,
          this.ball.side
        )
      ) {
        this.ball.y =
          paddle === this.player
            ? this.player.y - this.player.height
            : this.ai.y + this.ball.side;
        let boost =
          (this.ball.x + this.ball.side - paddle.x) /
          (paddle.width + this.ball.side);
        let angle = 0.25 * Math.PI * (1.5 * boost - 1);
        // Calculate contact value and update velocity
        let contact = Math.abs(angle) > 0.2 * Math.PI ? 1.5 : 1;
        this.ball.vel.y =
          contact *
          (paddle === this.player ? -1 : 1) *
          this.ball.speed *
          Math.cos(angle);
        this.ball.vel.x = contact * this.ball.speed * Math.sin(angle);
      }
      // On score
      if (this.ball.y + this.ball.side < 0 || this.ball.y > this.height) {
        // Based on the current paddle throw new ball
        this.playing = false;
        this.particleAnimation();
        if (this.interacted) {
          if (paddle === this.player) {
            this.aiScore += 1;
          } else {
            this.playerScore += 1;
          }
        }
        window.setTimeout(() => {
          this.playing = true;
          this.serveBall((paddle = this.player ? -1 : 1));
        }, 1000);
      }
    }
  }

  particleAnimation() {
    let yPos = this.ball.y > 0 ? this.height : 0;
    this.particleBlast.dispatchExplosion(this.ball.x, yPos);
    // Draw explosion(s)
  }

  drawBall() {
    this.ctx.fillColor = "#90EE90";
    this.ctx.fillRect(this.ball.x, this.ball.y, this.ball.side, this.ball.side);
  }

  showScore() {
    this.ctx.font = "30px Pixel";
    this.ctx.textAlign = "center";
    this.ctx.fillText(
      `${this.playerScore} : ${this.aiScore}`,
      this.width / 2,
      this.height / 2
    );
  }

  // Assign click events and create canvas
  prepareDom() {
    this.canvas.width = this.width;
    this.canvas.height = this.height;
    this.ctx = this.canvas.getContext("2d");

    this.particleBlast = new ParticleBlast(this.ctx);

    this.motionHandler = new MotionHandler(this.canvas);
    this.motionHandler.movementCallback = () => {
      this.interacted = true;
      this.player.x = this.motionHandler.innerMouseX - this.player.width / 2;
      console.info("tracking");
    };

    this.playing = true;
    this.initGame();
    this.render();
  }

  loop() {
    this.updateGame();
    this.drawGame();
  }

  // Set starting positions
  initGame() {
    this.player.x = this.player.width;
    this.player.y = this.height - this.player.height * 2;
    this.ai.x = this.width - (this.player.width + this.ai.width);
    this.ai.y = this.ai.height;
    this.serveBall(-1);
  }

  // Update positions
  updateGame() {
    this.updateBall();
    this.updatePlayer();
    this.updateAi();
  }

  // Clear canvas and draw objects at new positions
  drawGame() {
    const ctx = this.ctx;
    ctx.save();
    ctx.clearRect(0, 0, this.width, this.height);
    this.particleBlast.drawExplosion();
    ctx.fillStyle = "#90EE90";
    this.drawBall();
    ctx.fillStyle = "#fff";
    this.drawPlayer();
    this.drawAi();
    this.showScore();
    ctx.restore();
  }

  render() {
    setInterval(() => {
      this.loop();
    }, 10);
  }

  // Helper function to check intersection between two bounding boxes
  // Used for detecting paddle hitting the ball
  intersectHelper(ax, ay, aw, ah, bx, by, bw, bh, side) {
    if (this.ball.vel.y > 0) {
      return bx + bw / 2 > ax && bx - bw / 2 < ax + aw && by + bh > ay;
    } else {
      return bx + bw / 2 > ax && bx - bw / 2 < ax + aw && by - bh / 2 < ay;
    }
  }
}
