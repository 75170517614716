/**
 * MotionHandler class
 * Setup listeners and provide mouse position after recalculating it inside of the container bounds
 * This class instance is simplified since we only use the x axis position
 */
export default class MotionHandler {
  constructor(container) {
    this.container = container;
    // Direction indicator
    this.deltaX = 0;
    this.perviousPos = null;
    this.containerPos = null;
    // Recalculated value of mouse inside container bounds
    this.innerMouseX = null;
    this.active = true;
    this.movementCallback = () => {};
    this.create();
  }
  getContainerDimensions = () => {
    const pos = this.container.getBoundingClientRect();
    this.containerPos = pos;
  };
  setupEventListeners = () => {
    this.container.addEventListener("mousemove", (e) => {
      if (e.clientX > this.previousPos) {
        this.deltaX = 1;
      } else if (e.clientX === this.previousPos) {
        this.deltaX = 0;
      } else {
        this.deltaX = -1;
      }
      this.previousPos = e.clientX;
      // Get mouse coordinates inside container bounds
      this.innerMouseX = e.clientX - this.containerPos.x;
      this.movementCallback();
    });
    this.container.addEventListener("mouseover", (e) => {
      this.active = true;
    });
    this.container.addEventListener("mouseout", (e) => {
      this.active = false;
    });
  };
  create() {
    this.getContainerDimensions();
    this.setupEventListeners();
  }
}
