import React from "react";
import styled from "styled-components";
import styles from "../../styles.js";

import ProjectCard from "../ProjectCard/ProjectCard";
import WorkoutsScreenshot from "../../assets/workoutsScreenshot.PNG";
import MoviesScreenshot from "../../assets/movieBrowserScreen1.PNG";

const Projects = (props) => {
  const Wrapper = styled.div`
    width: 100vw;
    background: #111;
    overflow: hidden;
  `;

  const MainHeader = styled.h1`
    color: #fff;
    font-family: ${styles.rale};
    font-weight: 200;
    font-size: 40px;
    text-align: center;
    margin-top: 0;
    padding-top: 50px;
    padding-bottom: 100px;
    margin-bottom: 0;
    @media (max-width: 850px) {
      font-size: 35px;
    }
  `;

  return (
    <Wrapper>
      <MainHeader>My Projects</MainHeader>
      <ProjectCard
        color="#1cb495"
        tech={["React", "Redux", "Firebase", "Sass", "Animate.css"]}
        header="Workout Planner"
        describiton="Web app created for managing workouts. Workouts are stored in cloud Firestore database and the authentication
        is handled by the firebase auth API."
        github="https://github.com/KacperBiedka/wop"
        live="https://workout-planner-e9847.firebaseapp.com/"
        img={WorkoutsScreenshot}
      />
      <ProjectCard
        tech={["React", "Redux", "Node", "Express", "Sass", "Heroku"]}
        color="#eb4e7a"
        header="Movie Browser"
        describiton="React app created for browsing movies.
        All of the data is pulled from The Movie Database by the API that is handled on an express server."
        github="https://github.com/KacperBiedka/MovieBrowser"
        live="https://web-movie-browser.herokuapp.com/"
        img={MoviesScreenshot}
      />
      <ProjectCard
        tech={["JavaScript", "Handlebars", "Scss", "Jeeliz", "Gsap"]}
        color="#98FB98"
        header="Face Controlled Pong"
        describiton="[View this app on a mobile device!] - Vanilla js app created as a prototype for future job projects. Using AI, face position delta movement is calculated and used as position for pong paddle."
        live="https://test.wow-ads.de/2020/eyetracker_pong_prototype/release/"
      />
    </Wrapper>
  );
};

export default Projects;
