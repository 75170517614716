import React from "react";
import styled from "styled-components";
import styles from "../../styles.js";

import { ReactComponent as CssLogo } from "../../assets/css3.svg";
import { ReactComponent as HTMLLogo } from "../../assets/html5.svg";
import { ReactComponent as JSLogo } from "../../assets/javascript.svg";
import { ReactComponent as NodeLogo } from "../../assets/nodejs-icon.svg";
import { ReactComponent as GitLogo } from "../../assets/git.svg";
import { ReactComponent as HerokuLogo } from "../../assets/heroku.svg";
import { ReactComponent as FirebaseLogo } from "../../assets/firebase.svg";
import { ReactComponent as ReactLogo } from "../../assets/react.svg";
import { ReactComponent as ReduxLogo } from "../../assets/redux.svg";
import { ReactComponent as VueLogo } from "../../assets/vue-dot-js.svg";
import { ReactComponent as JestLogo } from "../../assets/jest.svg";
import { ReactComponent as SassLogo } from "../../assets/sass.svg";
import { ReactComponent as ExpressLogo } from "../../assets/expressjs-icon.svg";
import { ReactComponent as WebpackLogo } from "../../assets/webpack.svg";
import ThreeIcon from "../../assets/three-js.png";

import SkillIcon from "../SkillIcon/SkillIcon";

const Skills = (props) => {
  const Container = styled.div`
    width: 100vw;
    height: 400px;
    background: #151515;
    margin: auto;
    text-align: center;
    overflow: hidden;
    @media (max-width: 850px) {
      height: auto;
      padding-bottom: 50px;
      border-top: solid 1px #323232;
      border-bottom: solid 1px #323232;
    }
  `;

  const Header = styled.h1`
    font-family: ${styles.rale};
    font-weight: 200;
    font-size: 40px;
    margin-top: 0;
    padding-top: 50px;
    padding-bottom: 40px;
    color: #fff;
    @media (max-width: 850px) {
      font-size: 30px;
      padding-bottom: 20px;
    }
  `;

  return (
    <Container>
      <Header>Skills</Header>
      <SkillIcon color="#E34F26" source={<HTMLLogo />} />
      <SkillIcon color="#1572B6" source={<CssLogo />} />
      <SkillIcon color="orange" source={<JSLogo />} />
      <SkillIcon color="#1CA1F7" source={<ReactLogo />} />
      <SkillIcon color="#764ABC" source={<ReduxLogo />} />
      <SkillIcon color="#4FC08D" source={<VueLogo />} />
      <SkillIcon color="#C21325" source={<JestLogo />} />

      {window.innerWidth > 850 ? (
        <>
          <SkillIcon
            color="green"
            source={
              <NodeLogo
                style={{ width: "3vw", height: "3vw", color: "#fff" }}
              />
            }
          />
          <SkillIcon
            color="#333"
            source={
              <ExpressLogo
                style={{ width: "3vw", height: "3vw", color: "#fff" }}
              />
            }
          />
        </>
      ) : (
        <>
          <SkillIcon
            color="green"
            source={
              <NodeLogo
                style={{ width: "10vw", height: "10vw", color: "#fff" }}
              />
            }
          />
          <SkillIcon
            color="#333"
            source={
              <ExpressLogo
                style={{ width: "10vw", height: "10vw", color: "#fff" }}
              />
            }
          />
        </>
      )}
      <SkillIcon color="#F05032" source={<GitLogo />} />
      <SkillIcon color="#8DD6F9" source={<WebpackLogo />} />
      <SkillIcon color="#CC6699" source={<SassLogo />} />
      <SkillIcon color="orange" source={<FirebaseLogo />} />
      <SkillIcon color="#430098" source={<HerokuLogo />} />
      <SkillIcon
        color={"coral"}
        title={"Three.js"}
        scale={0.9}
        style={{ filter: "invert(100%)" }}
        image={ThreeIcon}
      />
    </Container>
  );
};

export default Skills;
