import React from "react";
import styled from "styled-components";
import { GithubSquare, Linkedin } from "styled-icons/fa-brands";
import { ExpandMore } from "styled-icons/material";

import styles from "../../styles";

const MainHeader = (props) => {
  const Container = styled.div`
    margin: auto;
    width: 50vw;
    float: center
    text-align: center;
    padding-top: 35vh
    @media (max-width: 1200px) {
        width: 70vw
    }
    @media (max-width: 850px) {
        width: 90vw;
        padding-top: 13vh
    }

  `;

  const Header = styled.h1`
    font-family: ${styles.fira};
    font-weight: 600;
    font-size: 42.5px;
    color: #fff;
    text-align: center;
    margin: auto;
    @media (max-width: 1200px) {
      font-size: 35px;
    }
    @media (max-width: 850px) {
      font-size: 30px;
    }
  `;

  const Subline = styled.h2`
    color: #fff;
    font-weight: 300;
    font-size: 30px;
    text-align: center;
    @media (max-width: 1200px) {
      font-size: 25px;
    }
    @media (max-width: 850px) {
      font-size: 22px;
    }
  `;

  const IconDiv = styled.div`
    margin: auto;
    margin-top: 5vh;
  `;

  const GithubIcon = styled(GithubSquare)`
    color: #fff;
    height: 45px;
    width: 45px;
    margin-right: 20px;
    transition: color 0.2s ease;
    pointer-events: all;
    :hover {
      color: ${styles.blue};
    }
  `;

  const LinkedinIcon = styled(Linkedin)`
    color: #fff;
    height: 45px;
    width: 45px;
    margin-left: 20px;
    transition: color 0.2s ease;
    pointer-events: all;
    :hover {
      color: ${styles.blue};
    }
  `;

  const ExpandIcon = styled(ExpandMore)`
    color: #fff;
    height: 55px;
    width: 55px;
    margin: 10% auto;
    pointer-events: all;
    cursor: pointer;
    animation: hover 2.5s infinite ease-in-out;
    @media (max-width: 800px) {
      margin: 20% auto;
    }
    @keyframes hover {
      from {
        transform: translateY(0);
      }
      15% {
        transform: translateY(10%);
      }
      30% {
        transform: translateY(0);
      }
      45% {
        transform: translateY(10%);
      }
      60% {
        transform: translateY(0);
      }
    }
  `;

  return (
    <Container>
      <Header>Hi, I'm Kacper Biedka</Header>
      <Subline>
        I play around with frontend stuff, AR, 3D and I am currently taking my
        first steps in mobile and backend development
      </Subline>
      <IconDiv>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://github.com/KacperBiedka"
        >
          <GithubIcon />
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.linkedin.com/in/kacper-biedka-b5a06a185/"
        >
          <LinkedinIcon />
        </a>
        <div onClick={() => props.scroll("skills")}>
          <ExpandIcon />
        </div>
      </IconDiv>
    </Container>
  );
};

export default MainHeader;
