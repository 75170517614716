import React, { Component } from "react";
import styled from "styled-components";
import SweetScroll from "sweet-scroll";
import ParticleBackground from "../ParticleBackground/ParticleBackground";

import Navbar from "../Navbar/Navbar";
import MainHeader from "../MainHeader/MainHeader";
import Skills from "../Skills/Skills";
import Projects from "../Projects/Projects";
import Contact from "../Contact/Contact";
import ContactModal from "../ContactModal/ContactModal";

class Home extends Component {
  state = {
    background: "",
    displayContactModal: null,
    value: 150,
    expandNav: false,
  };

  toggleContactModal = () => {
    if (this.state.displayContactModal) {
      this.setState({
        displayContactModal: null,
      });
    } else {
      this.setState({
        displayContactModal: (
          <ContactModal toggleContactModal={this.toggleContactModal} />
        ),
      });
    }
  };

  render() {
    const scroller = new SweetScroll();
    const css =
      "text-shadow: 0px 0px 10px #5C47B0; font-size: 25px; color: ghostwhite; font-family: Fira Sans, sans-serif; font-weight: 200;";
    console.log("%c Elton %s", css, "John ");
    const scroll = (id) => {
      scroller.toElement(document.getElementById(id));
    };

    const Wrapper = styled.div`
      width: 100vw;
      height: 100vh;
      background: transparent;
      background-size: cover;
      overflow: hidden;
      z-index: 1;
      pointer-events: none;
      position: absolute;
      @media (max-width: 850px) {
        height: 70vh;
      }
      @media (max-height: 650px) {
        height: auto;
      }
    `;

    const Container = styled.div`
      width: 100vw;
      height: 100vh;
      background: transparent;
      overflow: hidden;
    `;

    const ModalWrapper = styled.div`
      position: fixed;
      z-index: 3;
      width: 400px;
      height: 100px;
      background: transparent;
      margin: auto;
      left: 0;
      right: 0;
      text-align: center;
      pointer-events: ${this.state.displayContactModal ? "auto" : "none"};
      @media (max-width: 400px) {
        width: 100vw;
        height: 150px;
      }
    `;

    return (
      <>
        <ParticleBackground />
        <Wrapper>
          <ModalWrapper>{this.state.displayContactModal}</ModalWrapper>
          <Container onClick={this.displaySidenav}>
            <Navbar scroll={scroll} />
            <MainHeader scroll={scroll} />
          </Container>
        </Wrapper>
        <div id="skills">
          <Skills />
        </div>
        <div id="projects">
          <Projects />
        </div>
        <div id="contact">
          <Contact toggleContactModal={this.toggleContactModal} />
        </div>
      </>
    );
  }
}

export default Home;
