import React, { useRef } from "react";
import Pong from "../../pong/Pong";
import styled from "styled-components";
import styles from "../../styles.js";

const ProjectCard = (props) => {
  const openLinkInNewTab = (url) => {
    const win = window.open(url, "_blank");
    win.focus();
  };

  const showGithubLink = () => {
    if (props.github) {
      return (
        <ButtonRight onClick={() => openLinkInNewTab(props.github)}>
          Github
        </ButtonRight>
      );
    }
  };

  const setupPong = () => {
    const pong = new Pong(canvasRef.current);
    pong.prepareDom();
  };

  let canvasRef = useRef(null);

  const displayScreenshot = () => {
    if (props.img) {
      return (
        <ImageWrapper>
          <Image src={props.img} />
        </ImageWrapper>
      );
    } else {
      setTimeout(() => {
        setupPong();
      }, 100);
      return (
        <>
          <Controls></Controls>
          <Canvas width="50vw" height="25vw" ref={canvasRef} />
        </>
      );
    }
  };

  const Container = styled.div`
    width: 95vw;
    margin: auto;
    height: auto;
    padding: 100px 0;
    background: #111;

    @media (max-width: 1500px) {
      height: auto;
      padding: 0;
    }
  `;

  const TextSection = styled.div`
    width: 50%;
    display: inline-block;
    vertical-align: top;

    @media (max-width: 1500px) {
      display: block;
      width: 100%;
      padding-bottom: 50px;
      text-align: center;
    }

    @media (max-width: 500px) {
      padding-bottom: 100px;
    }
  `;

  const ImageSection = styled.div`
    width: 50%;
    display: inline-block;
    vertical-align: middle;

    @media (max-width: 1500px) {
      display: block;
      width: 100%;
      padding-bottom: 100px;
    }

    @media (max-width: 500px) {
      display: none;
    }
  `;

  const TechList = styled.ul`
    width: 100%
    list-style: none;
    text-align: left;
    padding: 0;
    padding-bottom: 75px;

    @media (max-width: 1500px) {
      display: none;
    }
    
  `;

  const ListElement = styled.li`
    font-size: 20px;
    font-family: ${styles.rale};
    font-weight: 200;
    display: inline;
    margin: 20px;
    height: 40px;
    border: solid 1px ${props.color}
    width: 40px;
    padding: 10px;
    background: #151515;
    color: ${props.color};

    :first-child {
      margin-left: 0 !important;
    }
  `;

  const Header = styled.h1`
    font-family: ${styles.rale};
    font-size: 40px;
    font-weight: 200;
    color: #fff;
    margin-top: 0;
    text-align: left;
    text-decoration-color: ${props.color};

    @media (max-width: 1500px) {
      text-align: center;
      font-size: 30px;
      text-decoration: underline;
      text-decoration-color: ${props.color};
    }
  `;

  const Describiton = styled.p`
    font-family: ${styles.fira};
    font-size: 30px;
    font-weight: 200;
    text-align: left;
    color: #fff;
    width: 90%;
    margin-left: 0;
    margin-bottom: 50px;

    @media (max-width: 1500px) {
      text-align: center;
      font-size: 25px;
      margin: auto;
      margin-top: 50px;
    }
  `;

  const ButtonLeft = styled.button`
    width: 250px;
    height: 65px;
    background: transparent;
    border: solid 1px #fff;
    font-family: ${styles.rale};
    font-size: 30px;
    font-weight: 200;
    color: #fff;
    display: inline-block;
    margin-right: 50px;
    transition: color 0.25s ease, border-color 0.25s ease;
    cursor: pointer;

    :focus {
      outline: none;
    }

    :hover {
      border-color: ${props.color};
      color: ${props.color};
    }

    @media (max-width: 1500px) {
      margin-top: 50px;
      width: 200px;
      height: 52px;
      font-size: 25px;
    }

    @media (max-width: 500px) {
      display: block;
      margin: auto;
      margin-top: 50px;
      width: 90%;
    }
  `;

  const ButtonRight = styled.button`
    width: 250px;
    height: 65px;
    background: transparent;
    border: solid 1px #fff;
    font-family: ${styles.rale};
    font-size: 30px;
    font-weight: 200;
    color: #fff;
    display: inline-block;
    transition: color 0.25s ease, border-color 0.25s ease;
    cursor: pointer;

    :focus {
      outline: none;
    }

    :hover {
      border-color: ${props.color};
      color: ${props.color};
    }

    @media (max-width: 1500px) {
      margin-top: 50px;
      width: 200px;
      height: 52px;
      font-size: 25px;
    }

    @media (max-width: 500px) {
      display: block;
      margin: auto;
      margin-top: 50px;
      width: 90%;
    }
  `;

  const ImageWrapper = styled.div`
    width: 100%
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  const Image = styled.img`
    width: 90%;
    display: block;
    margin: auto;
    border: solid 1px #333;
    @media (min-width: 1900px) {
      width: 100%;
    }
  `;

  const Canvas = styled.canvas`
    width: 100%;
    display: block;
    margin: auto;
    height: 25vw;
    min-height: 400px;
    border: solid 1px #333;
  `;

  const Controls = styled.div`
    position: absolute;
    top: 5%;
    right: 5%;
    display: flex;
    width: 30%;
    justify-content: space-between;
  `;

  return (
    <Container>
      <TextSection>
        <TechList>
          {props.tech.map((name) => {
            return <ListElement key={name}>{name}</ListElement>;
          })}
        </TechList>
        <Header>{props.header}</Header>
        <Describiton>{props.describiton}</Describiton>
        <ButtonLeft onClick={() => openLinkInNewTab(props.live)}>
          Preview
        </ButtonLeft>
        {showGithubLink()}
      </TextSection>
      <ImageSection>{displayScreenshot()}</ImageSection>
    </Container>
  );
};

export default ProjectCard;
