import React, { Component } from "react";
import styled from "styled-components";
import classes from "./Contact.module.scss";
import styles from "../../styles.js";
import Loader from "../Loader/Loader";

// EmailJs data
import uid from "../../uid.js";
import * as emailjs from "emailjs-com";

class Contact extends Component {
  state = {
    name: "",
    email: "",
    message: "",
    displayLoader: null
  };

  // eslint-disable-next-line
  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  updateNameState = event => {
    this.setState({
      name: event.target.value
    });
  };

  updateEmailState = event => {
    this.setState({
      email: event.target.value
    });
  };

  updateMessageState = event => {
    this.setState({
      message: event.target.value
    });
  };

  submitMessage = () => {
    if (
      this.emailRegex.test(this.state.email.trim()) &&
      this.state.name.trim() &&
      this.state.message.trim() &&
      !this.state.displayLoader
    ) {
      this.setState({
        displayLoader: <Loader />
      });
      emailjs.init(uid);
      let params = {
        from_name: this.state.name,
        message_html: this.state.message,
        reply_to: this.state.email
      };
      emailjs.send("gmail", "template_kP7DgxWb", params).then(
        response => {
          console.log("SUCCESS!", response.status, response.text);
          this.props.toggleContactModal();
          this.setState({
            name: "",
            email: "",
            message: "",
            displayLoader: null
          });
        },
        err => {
          console.log("FAILED...", err);
          alert("The message could not be sent :-(");
          this.setState({
            name: "",
            email: "",
            message: "",
            displayLoader: null
          });
        }
      );
    }
  };

  render() {
    const Header = styled.h1`
      font-family: ${styles.rale};
      font-weight: 200;
      font-size: 40px;
      margin-top: 0;
      padding-top: 50px;
      padding-bottom: 50px;
      @media (max-width: 1000px) {
        font-size: 35px;
      }
    `;

    const MessageLable = styled.p`
      font-family: ${styles.fira};
      font-size: 30px;
      font-weight: 200;
      color: #707070;
      margin: auto;
      padding-bottom: 0;
      margin-bottom: 0;
      margin-top: 50px;
      width: 900px;
      text-align: left;
      @media (max-width: 1000px) {
        width: 90vw;
        font-size: 25px;
      }
    `;

    const SubmitButton = styled.button`
      display: block;
      margin: auto;
      margin-top: 50px;
      width: 250px;
      height: 60px;
      font-family: ${styles.rale};
      font-size: 30px;
      font-weight: 400;
      background: transparent;
      border: ${
        this.emailRegex.test(this.state.email.trim()) &&
        this.state.name.trim() &&
        this.state.message.trim()
          ? "solid 1px " + styles.blue
          : "solid 1px #707070"
      }
      color: ${
        this.emailRegex.test(this.state.email.trim()) &&
        this.state.name.trim() &&
        this.state.message.trim()
          ? styles.blue
          : "#707070"
      }
      cursor: ${
        this.emailRegex.test(this.state.email.trim()) &&
        this.state.name.trim() &&
        this.state.message.trim()
          ? "pointer"
          : "default"
      }
      @media (max-width: 600px) {
          font-size: 25px;
          width: 90vw
      }
      :focus {
          outline: none;
      }

    `;

    return (
      <div className={classes.container}>
        <Header>Contact</Header>
        <input
          className={classes.nameInput}
          onChange={this.updateNameState}
          type="text"
          placeholder="Name"
          value={this.state.name}
        />
        <input
          className={classes.emailInput}
          onChange={this.updateEmailState}
          type="text"
          placeholder="Email"
          value={this.state.email}
        />
        <MessageLable>Message</MessageLable>
        <input
          className={classes.messageInput}
          onChange={this.updateMessageState}
          type="text"
          value={this.state.message}
        />
        <SubmitButton onClick={this.submitMessage}>Submit</SubmitButton>
        {this.state.displayLoader}
      </div>
    );
  }
}

export default Contact;
