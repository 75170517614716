import Particles from "react-particles-js";
import React from "react";

const ParticleBackground = (props) => {
  return (
    <Particles
      params={{
        particles: {
          number: {
            value: window.innerWidth < 850 ? 35 : 120,
          },
          color: {
            value: "#ED5DB7",
          },
          size: {
            value: 2,
          },
          line_linked: {
            shadow: {
              enable: false,
              color: "pink",
              blur: 5,
            },
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: true,
              mode: "grab",
            },
          },
        },
      }}
      style={{
        width: "100%",
        height: "100vh",
        backgroundColor: "#111",
        position: "absolute",
        zIndex: 0,
      }}
    />
  );
};

export default ParticleBackground;
