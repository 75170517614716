import React from "react";
import "./App.scss";

import Home from "./components/Home/Home";

function App() {
    // Analytics
  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
