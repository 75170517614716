import React from "react";
import styled from "styled-components";

const SkillIcon = (props) => {
  const Container = styled.div`
    width: 3vw;
    height: 3vw;
    padding: 10px;
    margin: 10px;
    background: ${props.color};
    fill: #fff;
    display: inline-block;
    text-align: center;
    @media (max-width: 850px) {
      width: 10vw;
      height: 10vw;
    }
  `;

  const Image = styled.span`
    height: 3vw;
    width: 3vw;
    transform: scale(${props.scale ? props.scale : 1});
    color: #fff;
    @media (max-width: 850px) {
      height: 10vw;
      width: 10vw;
    }
  `;

  const Picture = styled.img`
    height: 3vw;
    width: 3vw;
    transform: scale(${props.scale ? props.scale : 1});
    @media (max-width: 850px) {
      height: 10vw;
      width: 10vw;
    }
  `;

  // Support for both the Png/Jpg and Svg formats
  const renderImage = () => {
    if (props.source) {
      return <Image>{props.source}</Image>;
    } else {
      return (
        <Picture
          style={props.style}
          title={props.title}
          src={props.image}
        ></Picture>
      );
    }
  };

  return <Container>{renderImage()}</Container>;
};

export default SkillIcon;
