// Particle animation class
// The animation appears after the pong ball scores

export default class ParticleBlast {
    constructor (context) {
        this.particlesPerExplosion = 40;
        this.particlesMinSpeed = 3;
        this.particlesMaxSpeed = 6;
        this.particlesMinSize = 3;
        this.particlesMaxSize = 6;
        this.ctx = context;
        this.explosions = [];
    }
    drawExplosion () {
        if (this.explosions.length !== 0) {
            for (let i = 0; i < this.explosions.length; i++) {
                let explosion = this.explosions[i];
                let particles = explosion.particles;
                if (particles.length !== 0) {
                    for (var ii = 0; ii < particles.length; ii++) {
                        var particle = particles[ii];
                        // Check particle size
                        // If 0, remove
                        if (particle.size < 0) {
                            particles.splice(ii, 1);
                            return;
                        }
                        this.ctx.beginPath();
                        this.ctx.rect(particle.x, particle.y, particle.size, particle.size);
                        this.ctx.closePath();
                        this.ctx.fillStyle = 'rgb(' + particle.r + ',' + particle.g + ',' + particle.b + ')';
                        this.ctx.fill();
                        // Update
                        particle.x += particle.xv;
                        particle.y += particle.yv;
                        particle.size -= 0.1;
                    }
                } else {
                    this.explosions.splice(i, 1);
                }
            }
        }
    }

    createParticle (x, y) {
        let particle = {
            x: x,
            y: y,
            // Generate velocity and size
            xv: this.randInt(this.particlesMinSpeed, this.particlesMaxSpeed, false),
            yv: this.randInt(this.particlesMinSpeed, this.particlesMaxSpeed, false),
            size: this.randInt(this.particlesMinSize, this.particlesMaxSize, true),
            // Set different shades of one color
            r: '144',
            g: '238',
            b: '144'
        };
        return particle;
    }

    createExplosion (x, y) {
        let explosion = {
            particles: []
        };
        for (let i = 0; i < this.particlesPerExplosion; i++) {
            explosion.particles.push(this.createParticle(x, y));
        }
        return explosion;
    }

    dispatchExplosion (x, y) {
        this.explosions.push(this.createExplosion(x, y));
    }

    randInt (min, max, positive) {
        let num;
        if (positive === false) {
            num = Math.floor(Math.random() * max) - min;
            num *= Math.floor(Math.random() * 2) === 1 ? 1 : -1;
        } else {
            num = Math.floor(Math.random() * max) + min;
        }
        return num;
    }
}
