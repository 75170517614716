import React, { Component } from "react";
import styled from "styled-components";
import { Close } from "styled-icons/material";
import { Fade } from "react-reveal";
import styles from "../../styles.js";

class ContactModal extends Component {
  state = {
    displayModal: false,
  };

  componentDidMount = () => {
    this.setState({
      displayModal: true,
    });
  };

  closeContactModal = () => {
    this.setState({
      displayModal: false,
    });
    setTimeout(() => {
      this.props.toggleContactModal();
    }, 1000);
  };

  render() {
    const Wrapper = styled.div`
      width: 100%;
      height: 100%;
      background: #151515;
      border: solid 1px #333;
      @media (max-width: 400px) {
        border-bottom: solid 1px ${styles.blue};
      }
    `;

    const Message = styled.h1`
      color: #fff;
      line-height: 75px;
      margin: 5px auto;
      width: 80%;
      vertical-align: middle;
      font-size: 20px;
      font-family: ${styles.fira};
      font-weight: 200;
      padding-bottom: 5px;
      margin-bottom: 10px;
      border-bottom: solid 1px ${styles.blue};
      text-align: center;
      height: 50px;
      @media (max-width: 400px) {
        font-size: 20px;
        white-space: nowrap;
        width: 90%;
        border-bottom: none;
      }
    `;

    const CloseIcon = styled(Close)`
      height: 25px;
      width: 25px;
      color: #fff;
      float: right;
      padding-top: 5px;
      padding-right: 5px;
      cursor: pointer;
      position: absolute;
      left: 370px;
      @media (max-width: 400px) {
        left: 92vw;
      }
    `;

    return (
      <>
        <Fade top when={this.state.displayModal}>
          <Wrapper>
            <CloseIcon onClick={this.closeContactModal} />
            <Message>The message has been delivered!</Message>
          </Wrapper>
        </Fade>
      </>
    );
  }
}

export default ContactModal;
