import React from "react";
import styled from "styled-components";
import styles from "../../styles";

const Navbar = props => {
  const Container = styled.div`
    width: 100%;
    float: right;
    height: 75px;
    padding-bottom: 25px;
  `;

  const List = styled.ul`
    list-style: none;
    float: right;
    margin-top: 50px;
    pointer-events: all;
    @media (max-width: 850px) {
      display: none;
    }
  `;

  const ListItem = styled.li`
    color: #fff;
    font-family: ${styles.rale};
    font-weight: 200;
    font-size: 30px;
    display: inline-block;
    margin-right: 50px;
    cursor: pointer;
    padding-bottom: 0;
    border-bottom: solid 1px transparent;
    transition: border-color 0.3s ease, padding-bottom 0.3s ease;

    :hover {
      border-color: ${styles.blue};
      padding-bottom: 10px;
    }
  `;

  return (
    <Container>
      <List>
        <ListItem onClick={() => props.scroll("skills")}>Skills</ListItem>
        <ListItem onClick={() => props.scroll("projects")}>Projects</ListItem>
        <ListItem onClick={() => props.scroll("contact")}>Contact</ListItem>
        <ListItem>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://drive.google.com/file/d/1Hum7-PcKaqnp_a9n2aNACaLoMFMI5Sl5/view"
          >
            Resume
          </a>
        </ListItem>
      </List>
    </Container>
  );
};

export default Navbar;
